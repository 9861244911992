/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.json.stringify.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'FileDetail',
  components: {},
  props: {},
  data: function data() {
    return {
      ApproverName: '李明',
      authorityName: '一般权限',
      fileopen: '启用',
      fileinvalid: '否',
      filepsd: '123456',
      filetime: '2021-08-24 —— 2021-08-24',
      inductiondate: '2021-08-24',
      close: 0,
      jobnum: '10980',
      nation: '汉',
      idcard: '370612199802188029',
      tel: '17862666257',
      education: '本科',
      native: '山东烟台',
      address: '莱山区瑞达路8号',
      btnShow: true,
      fromName: '',
      depNameList: '',
      fromPath: '',
      backdepId: ''
    };
  },
  computed: {},
  watch: {},
  created: function created() {
    this.depNameList = this.$route.query.depNameList;
    this.fromPath = this.$route.query.fromPath;
    this.backdepId = this.$route.query.currentDepId;
  },
  mounted: function mounted() {
    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL);
      window.addEventListener('popstate', this.fileDetailback, false);
    }
  },
  beforeRouteEnter: function beforeRouteEnter(to, from, next) {
    //在next中写处理函数
    next(function (vm) {
      return vm.wherefrom(from.name);
    });
  },
  destroyed: function destroyed() {
    window.removeEventListener('popstate', this.fileDetailback, false);
  },
  methods: {
    wherefrom: function wherefrom(name) {
      // 我的  组织架构部门  公司直属
      if (name != 'My') {
        this.fromName = name;
        this.btnShow = true;
      } else {
        this.fromName = 'My';
        this.btnShow = false;
      }
    },
    fileDetailback: function fileDetailback() {
      //不是从我的进入该页面
      if (this.fromName == 'organizationList') {
        var depNameList = JSON.stringify(this.depNameList);
        this.$router.push({
          path: '/organizationList',
          query: {
            depNameList: depNameList,
            backdepId: this.backdepId
          }
        });
      } else if (this.fromName == 'fileList') {
        this.$router.push({
          path: '/fileList'
        });
      } else {
        this.$router.push({
          path: '/My'
        });
      }
    },
    havemoreclick: function havemoreclick() {
      if (this.close == 0) {
        this.close = 1;
      } else {
        this.close = 0;
      }
    }
  }
};